<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="product"
      :columns="columns">

      <template slot="search">
<!--        <a-button  ghost type="primary" @click="add">新增</a-button>-->
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit  @click="edit(record)"></action-edit>
      </template>

    </common-table>

    <commission-setting-edit ref="drawerEdit" @success="getList"></commission-setting-edit>
  </a-card>
</template>
<script>
  import CommissionSettingEdit from './CommissionSettingEdit'
  export default {
    components: {
      CommissionSettingEdit
    },
    data () {
      return {

      }
    },

    computed: {
      columns () {
        return [
          {
            title: '产品',
            dataIndex: 'name',
          },
          {
            title: '代理提成',
            dataIndex: 'commissionRate'
          },
          {
            title: '一级提成',
            dataIndex: 'commissionRate1'
          },
          {
            title: '二级提成',
            dataIndex: 'commissionRate2'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width:120,
            scopedSlots: { customRender: 'operation' }
          }
        ]
      }
    },
    methods: {
      add () {
        this.$refs.drawerEdit.show()
      },
      edit (record) {
        this.$refs.drawerEdit.show(record)
      },
      remove (record) {
        var that = this
        this.$confirm({
          title: '是否删除?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('commissionSetting/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },

      getList () {
        this.$refs.table.getData()
      }

    }
  }
</script>
<style lang="less" scoped>

  .news-logo {
    width: 60px;
    height: 60px;
  }
</style>
